$( document ).on('turbolinks:load', common_events)

function common_events(){
    // Used for creating a new FileList in a round-about way
    function FileListItem(a) {
        a = [].slice.call(Array.isArray(a) ? a : arguments)
        for (var c, b = c = a.length, d = !0; b-- && d;) d = a[b] instanceof File
        if (!d) throw new TypeError("expected argument to FileList is File or array of File objects")
        for (b = (new ClipboardEvent("")).clipboardData || new DataTransfer; c--;) b.items.add(a[c])
        return b.files
    }
    $('body').on('change', '#status_image',  change)
    function change() {
        const file = this.files[0]
            if (!file) return
            
            file.image().then(img => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            const maxWidth = 500
            const maxHeight = 400
            
            // calculate new size
            const ratio = Math.min(maxWidth / img.width, maxHeight / img.height)
            const width = img.width * ratio + .5|0
            const height = img.height * ratio + .5|0
            
            // resize the canvas to the new dimensions
            canvas.width = width
            canvas.height = height
            
            // scale & draw the image onto the canvas
            ctx.drawImage(img, 0, 0, width, height)
            // document.body.appendChild(canvas)
            
            // Get the binary (aka blob)
            canvas.toBlob(blob => {
                const resizedFile = new File([blob], file.name, file)
                const fileList = new FileListItem(resizedFile)
                
                // temporary remove event listener since
                // assigning a new filelist to the input
                // will trigger a new change event...
                this.onchange = null
                this.files = fileList
                this.onchange = change
            })
        })
    }

}

